<template>
  <div class="page-wrap pb-10">
    <div class="mb-3.5 py-3.5 px-2.5 bg-white">
      <div class="text-base font-medium leading-none">基础信息</div>
      <div class="mt-5">
        <div class="content-title text-sm font-normal">所在城市</div>
        <div class="flex items-center mt-4">
          <div class="screen-wrap" @click="screenChange(0)">
            <div class="flex-1 van-ellipsis">{{ screen_region.name }}</div>
            <van-icon name="arrow-down" color="#666" size="12" />
          </div>
          <div class="screen-wrap" @click="screenChange(1)">
            <div class="flex-1 van-ellipsis">{{ screen_city.name }}</div>
            <van-icon name="arrow-down" color="#666" size="12" />
          </div>
        </div>
      </div>
      <div class="mt-5">
        <div class="content-title text-sm font-normal">项目名称</div>
        <div class="flex items-center py-2 mt-2 border-b ">
          <input v-model="from.projectName" type="text" placeholder="请输入项目名称" class="flex-1 text-sm">
        </div>
      </div>
      <div class="content-title text-sm font-normal mt-5">大区交付</div>
      <!-- <van-checkbox-group class="mt-5" v-model="from.decorate" direction="horizontal">
        <van-checkbox name="1" shape="square">毛坯交付</van-checkbox>
        <van-checkbox name="2" shape="square">批量精装</van-checkbox>        
      </van-checkbox-group> -->
        <div class="group-item mt-3">
          <van-checkbox v-model="checked1" name="1" shape="square">毛坯交付</van-checkbox>
          <div class="flex items-center border-b flex-1 ml-2" v-show="checked1">
            <input v-model="building['1']" type="text" placeholder="请输入内容" class="flex-1 text-sm">
          </div>
        </div>
        <div class="group-item mt-3">
          <van-checkbox v-model="checked2" name="2" shape="square">精装交付</van-checkbox>
          <div class="flex items-center border-b flex-1  ml-2" v-show="checked2">
            <input v-model="building['2']" type="text" placeholder="请输入内容" class="flex-1 text-sm">
          </div>
        </div>
      <div class="content-title text-sm font-normal mt-5">验收项目</div>
      <!-- <van-checkbox-group class="mt-5" v-model="from.acceptanceType" direction="horizontal">
        <van-checkbox name="1" shape="square">展示区</van-checkbox>
        <van-checkbox name="2" shape="square">大盘区</van-checkbox>
      </van-checkbox-group> -->
      <van-field name="switch" label-width="50" label="展示区">
        <template #input>
          <van-switch v-model="switchChecked1" size="20" />
        </template>
      </van-field>
      <van-field v-if="switchChecked1" name="checkboxGroup" label-width="50" label="">
        <template #input>
          <van-checkbox-group v-model="optionData1" direction="horizontal">
            <van-checkbox class="mb-2" v-for="(item,index) in showOpt" :key="index" :name="String(item.id)" shape="square">{{item.name}}</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <van-field name="switch" label-width="50" label="大盘区">
        <template #input>
          <van-switch v-model="switchChecked2" size="20" />
        </template>
      </van-field>
      <van-field v-if="switchChecked2" name="checkboxGroup" label-width="50" label="">
        <template #input>
          <van-checkbox-group v-model="optionData2" direction="horizontal">
            <van-checkbox class="mb-2" v-for="(item,index) in centralOpt" :key="index" :name="String(item.id)" shape="square">{{item.name}}</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <div class="mt-5">
        <div class="content-title text-sm font-normal">项目图片</div>
        <div class="flex items-center mt-4 ">
          <com-upload
            key="overview"
            :Length="1"
            :file-list.sync="from.imgUrl"
          />
        </div>
      </div>
    </div>

    <div class="mb-3.5 py-3.5 px-2.5 bg-white" v-show="switchChecked1">
      <div class="text-base font-medium leading-none">样板房信息</div>
      <template v-for="(item,index) in from.houses">
        <houses :item="item" :Index="index" @back="minusHouse" :key="index"/>
      </template>
      
      
      <!-- <div class="mt-5">
        <div class="content-title text-sm font-normal">样板房名称</div>
        <div class="flex items-center mt-1 py-3.5 border-b" v-for="(item,index) in from.houses" :key="index">
          <input v-model="item.name" type="text" class="flex-1 text-sm" placeholder="请输入样板房名称">
          <img :src="require('assets/project/minus.png')" alt="" class="icon-xs" @click="minusHouse(index)">
        </div>
      </div> -->

      <div class="flex items-center justify-end mt-4" @click="addHouse">
        <img :src="require('assets/project/add.png') " class="icon-xs">
      </div>
    </div>

    <div class="submit-btn mx-2.5" @click="submit">提交</div>

    <van-action-sheet v-model="pop_picker">
      <van-picker
        :default-index="picker_active"
        show-toolbar
        :columns="picker_list"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
      />
    </van-action-sheet>
    <van-action-sheet v-model="pop_picker1">
      <van-picker
        :default-index="picker_active1"
        show-toolbar
        :columns="decorateList"
        value-key="name"
        @confirm="onConfirm1"
        @cancel="pop_picker1=false"
      />
    </van-action-sheet>
  </div>
</template>
<script>
import { Icon, ActionSheet, Picker, Toast, radioGroup, Radio ,checkboxGroup, checkbox, Field,  Switch} from 'vant'
import comUpload from 'components/comUpload'
import houses from './houses'
import { areaList, cityList, addProject, changeProject, getProject } from '@/api'
export default {
  name:'ProjectDetail',
  components:{
    [Icon.name]:Icon,
    [ActionSheet.name]:ActionSheet,
    [Picker.name]:Picker,
    [radioGroup.name]:radioGroup,
    [Radio.name]:Radio,
    [checkboxGroup.name]:checkboxGroup,
    [checkbox.name]:checkbox,
    [Field.name]:Field,
    [Switch.name]:Switch,
    comUpload,
    houses
  },
  data() {
    return {
      projectId: '',
      screen_region:{ name:'区域', index:-1 },
      screen_city:{ name:'城市', index:-1 },
      screen_type:-1,
      region_list:[],
      city_list:[],
      decorateList: [{
        name: '毛坯',
        type:  1
      },{
        name: '精装',
        type:  2
      }],
      picker_active1: '',
      pop_picker:false,
      pop_picker1: false,
      picker_list:[],
      picker_active:"1",
      decorateName: '请选择样板房装修',
      from:{
        id:'',
        areaId:'',
        cityId:'',
        building: {
          decorate1: '',
          decorate2: ''
        },
        decorate:[],
        houses:[],
        imgUrl:[],
        projectName:'',
        state:1,
        acceptanceType: [],
        optionData: {},
        enableHouses: 0
      },
      checked1: false,
      checked2: false,
      building: {
        1: '',
        2: ''
      },
      optionData1: [],
      optionData2: [],
      switchChecked1: false,
      switchChecked2: false,
      centralOpt: [],
      showOpt: [],
    }
  },
  created() {
    if (this.$route.query.id) {
      this.projectId = this.$route.query.id
      this.getProject()
      // this.init()
    } else {
      this.getAreaList()

      this.getProject()
    }
  },
  methods:{
    onConfirm(value, index) {
      // console.log(`当前值：${value}, 当前索引：${index}`)
      // this.status_active = value
      if (value) {
        const data = value
        data.index = index
        if (this.screen_type == 0) {
          this.screen_region = data
          this.from.areaId = data.id
          this.from.cityId = ''
          this.screen_city = { name:'城市', index:1 }
          this.getCityList(value.id)
        } else if (this.screen_type == 1) {
          this.screen_city = data
          this.from.cityId = data.id
        }
      }
      this.pop_picker = false
    },
    onConfirm1(value) {
      // this.decorateName = value.name
      // this.from.decorate = value.type
      // this.pop_picker1 = false
    },
    onChange(picker, value, index) {
      console.log(`当前值：${value}, 当前索引：${index}`)
    },
    onCancel() {
      // console.log('取消')
      this.pop_picker = false
    },
    getProject() {
      getProject(this.projectId).then(res=>{
        var data = res.data.data
        this.centralOpt = data.centralOpt
        this.showOpt = data.showOpt
        
        data.optionData = JSON.parse(data.optionData)
        for (var k in data.optionData) {
          this['optionData'+k] = data.optionData[k].split(',')
        }
        data.decorate = data.decorates?data.decorates.split(','):[]
        data.acceptanceType = data.acceptanceType?data.acceptanceType.split(','):[]
        for (var item of data.acceptanceType) {
          this['switchChecked'+item] = item?true:false
        }
        // this.picker_active = res.data.data.decorate
        // this.decorateName = res.data.data.decorate==1?'毛坯':'精装'
        this.init(data)
      })
    },
    screenChange(screen_type) {
      console.log('screen_type', screen_type)
      // screen_type 0区域 1城市
      let screen_list = []
      let screen_active = 0
      if (screen_type == 0) {
        screen_list = this.region_list
        screen_active = this.screen_region.index
      } else if (screen_type == 1) {
        if (this.screen_region.index < 0) {
          Toast('请先选择区域')
          return
        } else {
          screen_list = this.city_list
          screen_active = this.screen_city.index
        }
      }
      this.screen_type = screen_type
      this.picker_list = screen_list
      this.picker_active = screen_active
      this.pop_picker = true

      // console.log('screenChange112', screen_list, screen_active)
    },

    init(query) {
      this.from = {
        areaId:query.areaId * 1,
        cityId:query.cityId * 1,
        cityId:query.cityId * 1,
        decorate: [],
        houses:query.houses ? query.houses : [],
        imgUrl:query.imgUrl?query.imgUrl.split(',http'):[],
        projectName:query.projectName,
        state:query.state * 1 ? query.state : 1,
        acceptanceType: [],
        building: {},
        enableHouses:0
      }
      // this.checked1 = query.decorate[0]
      // this.checked2 = query.decorate[1]
      this.checked1 = false
      this.checked2 = false
      for (var i of query.decorate) {
        var k = 'checked'+Number(i)
        this[k] = true
      }
      var building = query.building?JSON.parse(query.building):{}
      for (var k in building) {
        this.building[k] = building[k]
      }
      if (query.sort) {
        this.from.sort = query.sort * 1
      }
      if (query.id) {
        this.from.id = query.id * 1
      }
      if (query.decorate) {
        this.from.decorate = query.decorate
      }
      // if (query.decorate) {
      //   this.from.acceptanceType = query.acceptanceType
      // }
      // console.log(this.from)
      this.getAreaList(query.areaId)
    },
    getAreaList(areaId) {
      areaList({}).then(res => {
        // console.log('areaList', res.data, areaId)
        let checkArea = { name:'区域', index:-1 }
        this.region_list = res.data.data.map((item, index) => {
          item.name = item.areaName
          item.text = item.areaName
          item.index = index
          if (item.id == areaId) {
            checkArea = item
          }
          return item
        })
        if (areaId) {
          this.screen_region = checkArea
          this.getCityList(areaId)
        }
      })
    },
    getCityList(areaId) {
      cityList({ areaId:areaId }).then(res => {
        // console.log('cityList', res.data)
        this.city_list = res.data.data.map((item, index) => {
          item.name = item.cityName
          item.text = item.cityName
          item.index = index
          // console.log('city_id', this.$route.query.cityId * 1 == index)

          if (this.from.cityId==item.id) {
            this.screen_city = item
          }
          return item
        })
      })
    },
    minusHouse(index) {
      this.from.houses.splice(index, 1)
      // console.log('minusHouse', id)
    },
    addHouse() {
      const house = {
        id:'',
        name:'',
        projectId:'',
        decorate: ''
      }
      const house_data = this.from.houses
      if (house_data.length == 0 || house_data[house_data.length - 1].name) {
        this.from.houses.push(house)
      } else {
        Toast('请填写样板房项目名称')
      }
      console.log('addHouse')
    },
    submit() {
      const from_data = JSON.parse(JSON.stringify(this.from))
      from_data.imgUrl = from_data.imgUrl ? from_data.imgUrl.join() : ''
      from_data.decorates = []
      from_data.acceptanceType = []
      from_data.optionData = {}
      if (this.switchChecked1) {
        from_data.acceptanceType.push('1')
        from_data.optionData['1'] = this.optionData1.join(',')
        
        for (var item of this.showOpt) {
            if (item.enableHouses==1) {
              for (var im of this.optionData1.join(',')) {
                if (item.id==im) {
                  from_data.enableHouses = 1
                  break
                }
              }
            }
        }
      } else {
        //只有展示区开启才可以填样板房
        from_data.houses = []
      }
      if (this.switchChecked2) {
        from_data.acceptanceType.push('2')
        from_data.optionData['2'] = this.optionData2.join(',')
      }
      from_data.acceptanceType = from_data.acceptanceType.join(',')
      from_data.optionData = JSON.stringify(from_data.optionData)
      if (from_data.houses.length == 1 && from_data.houses[0].name == '') {
        from_data.houses = []
      }
      from_data.building = {}
      from_data.decorates = []
      if (this.checked1) {
        from_data.building['1'] = this.building['1']
        from_data.decorates.push(1)
      }
      if (this.checked2) {
        from_data.building['2'] = this.building['2']
        from_data.decorates.push(2)
      }
      from_data.decorates = from_data.decorates.join(',')
      from_data.building = JSON.stringify(from_data.building)
      new Promise((resolve) => {
        this.formVerify(from_data, resolve)
      }).then((status) => {
        // console.log('Promise', status)
        for (var item of from_data.houses) {
          delete item.score
        }
        delete from_data.decorate
        if (status) {
          if (this.$route.query.id) {
            this.changeProject(from_data)
          } else {
            this.addProject(from_data)
          }
        }
      })

      // console.log('submit', from_data)
    },
    addProject(from_data) {
      addProject(from_data).then(res => {
        console.log('addProject', res)
        this.$toast.success('新增成功');
        this.pagesBack()
      }).catch(()=>{
        this.$toast.clear()
      })
    },
    changeProject(from_data) {
      console.log('changeProject from_data', from_data)
      changeProject(from_data.id, from_data).then(res => {
        console.log('changeProject ststus', res)
        this.$toast.success('修改成功');
        this.pagesBack()
      }).catch(()=>{
        this.$toast.clear()
      })
    },
    formVerify(data, resolve) {
      console.log('formVerify', data, resolve)
      let status = true
      data.imgUrl = data.imgUrl==''?'https://acceptanceoss.chinawans.com/acceptance/bc78ed904800bd5f955a34f7cea5dad.png':data.imgUrl
      if (data.areaId == '') {
        Toast('请选择区域')
        status = false
      } else if (data.cityId == '') {
        Toast('请选择城市')
        status = false
      } else if (data.projectName == '') {
        Toast('请填写项目名称')
        status = false
      } else if (data.decorates.length==0) {
        Toast('请选择大区交付')
        status = false
      } else if (data.acceptanceType.length==0) {
        Toast('请至少开启一个验收项目')
        status = false
      } 
      // else if (data.imgUrl == '') {
      //   // Toast('请上传项目图片')
      //   // status = false
      //   data.imgUrl = data.imgUrl==''?'https://acceptanceoss.chinawans.com/acceptance/bc78ed904800bd5f955a34f7cea5dad.png':data.imgUrl
      // } 
      else  {
        status = true
        for (var item of data.houses) {
          delete item.state
          if (item.decorate=='') {
            Toast('请选择样板房装修')
            status = false
          }
        }
      }
      console.log(true);
      resolve(status)
    },
    pagesBack() {
      setTimeout(()=>{
        this.$router.go(-1)
      }, 1500)
      
    }
  }

}
</script>
<style scoped>
.page-wrap>>>.group-item{
  display: flex;
  align-items: center;
  width: 100%;
  height: 20px;
}

</style>
